<template>
  <div class="hamburger-menu">
    <button class="hamburger-menu__button" @click="isActive = !isActive" :class="{ 'is-active': isActive }">
      <span class="material-symbols-rounded header-content__icon">menu</span>
    </button>
    <div class="hamburger-menu__content" :class="{ 'is-active': isActive }">
      <div class="hamburger-menu__close">
        <button @click="isActive = !isActive" class="hamburger-menu__close-button" aria-label="Cerrar menú"><span class="material-symbols-rounded">close</span></button>
      </div>
      <ul class="hamburger-menu__list">
        <li class="hamburger-menu__item" @click="isActive = !isActive" v-for="(route, index) in routes" :key="index">
          <router-link :to="route.route">{{ route.name }}</router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "MenuComponent",
  data() {
    return {
      isActive: false,
      routes: [
        {
          name       :  'Sportsbook',
          route      :  'sportsbook'
        },
        {
          name       :  'Racebook',
          route      :  'racebook'
        },
        {
          name       :  'Live Betting',
          route      :  'live-betting'
        },
        {
          name       :  'Casino',
          route      :  'casino'
        },
      ]
    };

  },
};
</script>
