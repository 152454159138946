<template>
  <header class="header">
    <div class="header-content max-container">
        <a href="/" class="header-content__container-logo">
            <brand-header :image="urlImage" :altImage="altImagen"/>
        </a>
    </div>
  </header>
</template>

<script>
  import BrandHeader from '@/components/brand-header.vue';


  export default {
    name: "HeaderComponent",
    components: {
      BrandHeader
    },
    data() {
      return {
        urlImage: require('../assets/images/logo.svg'),
        altImage: 'wagerStore',
      }
    },
  };
</script>
