<template>
  <div class="modal-backdrop" v-if="showModalClassic">
    <div class="modal-content__heading">
      <h3>login classic</h3>
      <button class="close-button" @click="closeModalClassic">
        <span class="material-symbols-rounded">close</span>
      </button>
    </div>
    <div class="modal-content">

      <!-- Mobile form -->
      <form class="login__form form__mobile" method="post" :action="`//mobile.${backendUrl}/DefaultLogin.aspx`">
        <div class="login__form--item">
          <input type="hidden" name="siteID" id="siteID" :value="`${DGS_SITEID}`"/>
          <input type="hidden" name="errorURL" :value="`//www.${backendUrl}/?login-error`"/> 
        </div>

        <div class="login__form--item">
          <label class="login__form--label" for="account">Username</label>
          <input class="login__form--input login__form--input-modal" type="text" name="account" id="account" placeholder="USERNAME">
        </div>
      
        <div class="login__form--item">
          <label class="login__form--label" for="password">Password</label>
          <input class="login__form--input login__form--input-modal" type="password" name="password" id="password" placeholder="PASSWORD">
        </div>

        <div class="login__btns-login">
          <button class="login-button" type="submit">Login</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ModalClassicComponent',
    setup() {
      const DGS_SITEID = 980;
      const backendUrl = "dublindays.online";

      return {
        DGS_SITEID,
        backendUrl,
      }
    },
    data() {
      return {
        showModalClassic: false
      }
    },
    methods: {
      openModalClassic() {
        this.showModalClassic = true;
      },
      closeModalClassic() {
        this.showModalClassic = false;
      }
    }
  }
</script>