<template>
    <a href="/" class="brand-header">
        <img class="brand-header__img" src="@/assets/images/logo.svg" alt="winbook" width="auto" height="auto"/>
    </a>
</template>

<script>
    export default {
        name: "BrandComponent",
        setup() {
        },
    };
</script>
